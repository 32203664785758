import HTTPService from '@/api/http/HTTPService'
import SiteBrandSortInterface from './SiteBrandSortInterface'

export default {
  getAll: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/brand`),
  getOne: (siteSlug: string, brandSlug: string) => HTTPService.get(`site/${siteSlug}/brand/${brandSlug}`),
  select: (siteSlug: string, brandSlug: string, include_models: boolean) => HTTPService.post(`site/${siteSlug}/brand/${brandSlug}/select`, {
    include_models,
  }),
  unselect: (siteSlug: string, brandSlug: string) => HTTPService.post(`site/${siteSlug}/brand/${brandSlug}/unselect`),
  getForSort: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/brand/reorder`),
  sort: (siteSlug: string, payload: SiteBrandSortInterface) => HTTPService.post(`site/${siteSlug}/brand/reorder`, payload),
}
