<template>
  <b-card>
    <div class="row mb-2">
      <div class="col">
        <b-button
          class="d-flex align-items-center"
          variant="primary"
          @click.prevent="showAddPopup = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-1"
          />
          Добавить контакт
        </b-button>
      </div>
      <div class="col d-flex justify-content-end">
        <b-button
          class="d-inline-flex align-items-center"
          variant="primary"
          :to="{name: 'contacts_sort'}"
        >
          <feather-icon
            icon="GridIcon"
            size="18"
            class="mr-1"
          />
          Сортировка
        </b-button>
      </div>
    </div>
    <div
      v-if="contacts.length"
    >
      <contacts-list
        :contacts="contacts"
        @updated="updateContact"
        @deleted="deleteContact"
      />
    </div>
    <div v-else>
      Нет контактов
    </div>
    <add-contact-popup
      v-if="showAddPopup"
      @added="addContact"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton,
} from 'bootstrap-vue'

import Contact from '@/api/http/models/contact/Contact'

import AddContactPopup from './AddContactPopup.vue'
import ContactsList from './ContactsList.vue'

export default {
  components: {
    BCard,
    BButton,
    AddContactPopup,
    ContactsList,
  },
  data() {
    return {
      showAddPopup: false,
      contacts: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    await this.fetchContacts()
  },
  methods: {
    async fetchContacts() {
      const response = await Contact.getAll(this.currentSite.slug)

      this.contacts = response.data || []
    },
    addContact(contact) {
      this.contacts.push(contact)
    },
    updateContact(contact) {
      const index = this.contacts.findIndex(cur => cur.id === contact.id)

      if (index !== -1) {
        this.contacts.splice(index, 1, contact)
      }
    },
    deleteContact(contact) {
      const index = this.contacts.findIndex(cur => cur.id === contact.id)

      if (index !== -1) {
        this.contacts.splice(index, 1)
      }
    },
  },
}
</script>
