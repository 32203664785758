<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-12 col-lg-2">
          Иконка
        </div>
        <div class="col-12 col-lg-4">
          Адрес
        </div>
        <div class="col-12 col-lg-4">
          Название
        </div>
        <div class="col-12 col-lg-2 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="contact in contacts"
        :key="contact.id"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-2">
                <div class="content-pin-wrap">
                  <img
                    v-if="contact.icon"
                    :src="contact.icon"
                    alt="icon"
                  >
                  <svg
                    v-else
                    width="50"
                    height="58"
                    viewBox="0 0 50 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M50 24.65C50 38.2638 38.8071 49.3 25 49.3C11.1929 49.3 0 38.2638 0 24.65C0 11.0362 11.1929 0 25 0C38.8071 0 50 11.0362 50 24.65Z"
                      fill="#7367f0"
                    />
                    <path
                      d="M14.1698 42.8982C13.6237 42.1367 13.6795 41.0982 14.304 40.3997L23.0671 30.5976C23.8395 29.7337 25.1821 29.705 25.9907 30.5351L35.5959 40.3952C36.2722 41.0895 36.3533 42.1687 35.7885 42.9563L26.6253 55.7337C25.8274 56.8463 24.1726 56.8463 23.3747 55.7337L14.1698 42.8982Z"
                      fill="#7367f0"
                    />
                    <ellipse
                      cx="24.9998"
                      cy="24.65"
                      rx="2.94118"
                      ry="2.9"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                {{ contact.address }}
              </div>
              <div class="col-12 col-lg-4">
                {{ contact.title }}
              </div>
              <div class="col-12 col-lg-2">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(contact)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    @click.prevent="openDeletePopup(contact)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-contact-popup
      v-if="showEditPopup && currentContactId"
      :key="currentContactId"
      :contact-id="currentContactId"
      @updated="$emit('updated', $event)"
      @hidden="hideEditPopup"
    />
    <delete-contact-popup
      v-if="showDeletePopup && currentContactId"
      :key="currentContactId"
      :contact-id="currentContactId"
      @deleted="$emit('deleted', $event)"
      @hidden="hideDeletePopup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'

import EditContactPopup from './EditContactPopup.vue'
import DeleteContactPopup from './DeleteContactPopup.vue'

export default {
  components: {
    BButton,
    EditContactPopup,
    DeleteContactPopup,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showDeletePopup: false,
      currentContactId: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  methods: {
    openEditPopup(contact) {
      this.showEditPopup = true
      this.currentContactId = contact.id
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.currentContactId = null
    },
    openDeletePopup(contact) {
      this.showDeletePopup = true
      this.currentContactId = contact.id
    },
    hideDeletePopup() {
      this.showDeletePopup = false
      this.currentContactId = null
    },
  },
}
</script>
