import HTTPService from '@/api/http/HTTPService'

import ContactInterface from './ContactInterface'

export default {
  getAll: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/contact`),
  getOne: (siteSlug: string, contactId: number) => HTTPService.get(`site/${siteSlug}/contact/${contactId}`),
  add: (siteSlug: string, payload: ContactInterface) => HTTPService.post(`site/${siteSlug}/contact`, payload),
  update: (siteSlug: string, contactId: number, payload: ContactInterface) => HTTPService.put(`site/${siteSlug}/contact/${contactId}`, payload),
  delete: (siteSlug: string, contactId: number) => HTTPService.delete(`site/${siteSlug}/contact/${contactId}`),
  sort: (siteSlug: string, payload: ContactInterface) => HTTPService.post(`site/${siteSlug}/contact/reorder`, payload),
}
